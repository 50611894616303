import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';

const Payment = () => {
	const { register, handleSubmit, errors } = useForm();
	const [amount, setAmount] = useState(0);
	const [showResponse, setShowResponse] = useState(false);
	const [hideOptions, setHideOptions] = useState(true);
	const onSubmit = (data) => {
		setHideOptions(false);
		data.paymentOption &&
			axios
				.post(`http://www.gobeyondhardship.ca/cbe/mailer.php`, { data })
				.then((res) => {
					if (res.data === 'success') {
						setShowResponse(true);
					}
				});
	};
	const monthNames = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];
	const currentDate = new Date();

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className='bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4  my-2 mx-6  flex flex-col overflow-hiddenborder-solid rounded-lg border-2'>
					<div className='-mx-3 md:flex mb-6'>
						<h1>SELF SERVICE PAYMENT PLAN APPROVAL FORM</h1>
						<input
							type='hidden'
							name='subject'
							value='SELF SERVICE PAYMENT PLAN APPROVAL FORM'
							ref={register({ required: true })}
						></input>
					</div>
					<div className='-mx-3 md:flex mb-6'>
						<p>
							WE UNDERSTAND THAT MANY OF US ARE FACED WITH VERY DIFFICULT
							FINANCIAL CIRCUMSTANCES. THIS IS ESPECIALLY TRUE IN THIS
							POST-COVID WORLD. IF YOU ARE EXPERIENCING FINANCIAL CIRCUMSTANCES
							BEYOND YOUR CONTROL WE WOULD LIKE TO HELP. WE INVITE YOU TO FILL
							IN AND SEND THIS FORM AND OUR HARDSHIP DEPARTMENT WIL REVIEW AND
							TAKE STEPS TO ASSIST.
						</p>
					</div>
					<div className='-mx-3 md:flex mb-6'>
						<p className='text-xs'>
							WHEN YOU ARE FILLING AND SENDING THIS FORM WE ARE ASKING YOU TO
							AGREE TO ONE OF OUR LICENSED REPRESENTATIVES REVIEWING YOUR
							INFORMATION AND CONTACTING YOU REGARDING POSSIBLE FURTHER STEPS.
							ANY INFORMATION PROVIDED WILL STRICTLY BE USED IN REFERENCE TO
							YOUR OUTSTANDING BALANCE ASSIGNED WITH OUR OFFICE AND WILL BE
							HANDLED IN ACCORDANCE WITH PIPEDA – THE PERSONAL INFORMATION
							PROTECTION AND ELECTRONIC DOCUMENTS ACT. OUR OFFICE IS STRICTLY
							FORBIDDEN TO SHARE THIS INFORMATION WITH THIRD PARTIES SUCH AS
							RELATIVES, FRIENDS OR OTHER BUSINESSES.
						</p>
					</div>
					<div className='-mx-3 md:flex mb-6'>
						<div className='md:w-1/2 px-3 '>
							<label
								className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2'
								htmlFor='grid-first-name'
							>
								First Name
							</label>
							<input
								className='appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4'
								id='grid-first-name'
								name='firstName'
								type='text'
								placeholder='Jane'
								ref={register({
									required: true,
									maxLength: 30,
									pattern: /^[A-Za-z]+$/i,
								})}
							/>
							<p className='text-red-600 text-xs italic'>
								{errors.firstName && 'First name is required'}
							</p>
						</div>
						<div className='md:w-1/2 px-3'>
							<label
								className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2'
								htmlFor='grid-last-name'
							>
								Last Name
							</label>
							<input
								className='appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4'
								id='grid-last-name'
								name='lastName'
								type='text'
								placeholder='Doe'
								ref={register({ required: true })}
							/>
							<p className='text-red-600 text-xs italic'>
								{errors.lastName && 'Last name is required'}
							</p>
						</div>
					</div>
					<div className='-mx-3 md:flex mb-6'>
						<div className='md:w-1/3 px-3 mb-6 md:mb-0'>
							<label
								className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2'
								htmlFor='grid-account-number'
							>
								Account number:
							</label>
							<input
								className='appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4'
								id='grid-account-number'
								name='accountNumber'
								ref={register({ required: true })}
								type='text'
							/>
							<p className='text-red-600 text-xs italic'>
								{errors.accountNumber && 'Account number is required'}
							</p>
						</div>
						<div className='md:w-1/3 px-3'>
							<label
								className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2'
								htmlFor='grid-creditor'
							>
								Creditor:
							</label>
							<input
								className='appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4'
								id='grid-creditor'
								name='creditor'
								ref={register({ required: true })}
								type='text'
							/>
							<p className='text-red-600 text-xs italic'>
								{errors.creditor && 'Creditor information  is required'}
							</p>
						</div>
						<div className='md:w-1/3 px-3'>
							<label
								className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2'
								htmlFor='grid-amount-of-debt'
							>
								Amount of Debt:
							</label>
							<input
								className='appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4'
								id='grid-amount-of-debt'
								name='ammountOfDebt'
								placeholder='99.99'
								min='1'
								ref={register({ required: true, min: 1, max: 9999999999 })}
								onChange={(e) => {
									setAmount(e.target.value);
								}}
								type='number'
							/>
							<p className='text-red-600 text-xs italic'>
								{errors.ammountOfDebt &&
									'Ammount of debt information is required'}
							</p>
						</div>
					</div>
					{hideOptions ? (
						<div className='-mx-3 md:flex mb-6'>
							<div className='md:w-1/2 px-3 mb-6 md:mb-0'>
								<button
									type='submit'
									className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full'
								>
									SHOW ME THE PAYMENT OPTIONS
								</button>
							</div>
						</div>
					) : (
						<>
							<div className='-mx-3 mb-1'>
								<input
									id='optionOne2MonthPayment'
									name='paymentOption'
									type='radio'
									className='hidden'
									ref={register({ required: true })}
									value='optionOne2MonthPayment'
								/>
								<label
									htmlFor='optionOne2MonthPayment'
									className='flex items-center cursor-pointer block uppercase tracking-wide text-grey-darker text-sm font-bold'
								>
									<span className='w-4 h-4 inline-block mr-1 border border-black'></span>
									Option one{' '}
								</label>
							</div>

							<div className='ml-3 md:flex mb-1 text-xs italic'>
								<div className='md:w-1/5 px-3 '>
									{monthNames[currentDate.getMonth()]}:{' '}
									<span className='font-bold'>
										${(amount * 0.5).toFixed(2)}
									</span>
								</div>
								<div className='md:w-1/5 px-3'>
									{monthNames[currentDate.getMonth() + 1]}:{' '}
									<span className='font-bold'>
										${(amount * 0.5).toFixed(2)}
									</span>
								</div>
								<div className='md:w-1/3 px-3'></div>
							</div>
							<div className='ml-3 mb-6 text-xs  px-3'>
								<p>
									A 2 month payment plan with 50% / 50% this month and next
									month
									<br />
									<span className='text-gray-600 text-xs italic'>
										(This option will not affect your Credit Score)
									</span>
								</p>
							</div>
							<div className='-mx-3 mb-1 border-b-2'></div>
							<div className='-mx-3 mb-1'>
								<input
									id='optionTwo2MonthPayment'
									name='paymentOption'
									type='radio'
									className='hidden'
									ref={register({ required: true })}
									value='optionTwo3MonthPayment'
								/>
								<label
									htmlFor='optionTwo2MonthPayment'
									className='flex items-center cursor-pointer block uppercase tracking-wide text-grey-darker text-sm font-bold'
								>
									<span className='w-4 h-4 inline-block mr-1 border border-black'></span>
									Option two
								</label>
							</div>

							<div className='ml-3 md:flex mb-1 text-xs italic'>
								<div className='md:w-1/5 px-3'>
									{monthNames[currentDate.getMonth()]}:{' '}
									<span className='font-bold'>
										${(amount * 0.5).toFixed(2)}
									</span>
								</div>
								<div className='md:w-1/5 px-3'>
									{monthNames[currentDate.getMonth() + 1]}:{' '}
									<span className='font-bold'>
										${(amount * 0.3).toFixed(2)}
									</span>
								</div>
								<div className='md:w-1/5 px-3'>
									{monthNames[currentDate.getMonth() + 2]}:{' '}
									<span className='font-bold'>
										${(amount * 0.2).toFixed(2)}
									</span>
								</div>

								<div className='md:w-2/5 px-3'></div>
							</div>
							<div className='ml-3 mb-6 text-xs  px-3'>
								<p>
									A 3 month payment plan with 50% / 30% / 20% with current month
									/ next month / following month <br />
									<span className='text-gray-600 text-xs italic'>
										(Credit Score affected)
									</span>
								</p>
							</div>
							<div className='-mx-3 mb-1 border-b-2'></div>
							<div className='-mx-3 mb-1'>
								<input
									id='optionThree5MonthPayment'
									name='paymentOption'
									type='radio'
									className='hidden'
									ref={register({ required: true })}
									value='optionThree5MonthPayment'
								/>
								<label
									htmlFor='optionThree5MonthPayment'
									className='flex items-center cursor-pointer block uppercase tracking-wide text-grey-darker text-sm font-bold'
								>
									<span className='w-4 h-4 inline-block mr-1 border border-black'></span>
									Option three
								</label>
							</div>

							<div className='ml-3 md:flex mb-1 text-xs italic'>
								<div className='md:w-1/5 px-3'>
									{monthNames[currentDate.getMonth()]}:{' '}
									<span className='font-bold'>
										${(amount * 1.02 * 0.2).toFixed(2)}
									</span>
								</div>
								<div className='md:w-1/5 px-3'>
									{monthNames[currentDate.getMonth() + 1]}:{' '}
									<span className='font-bold'>
										${(amount * 1.02 * 0.2).toFixed(2)}
									</span>
								</div>
								<div className='md:w-1/5 px-3'>
									{monthNames[currentDate.getMonth() + 2]}:{' '}
									<span className='font-bold'>
										${(amount * 1.02 * 0.2).toFixed(2)}
									</span>
								</div>
								<div className='md:w-1/5 px-3'>
									{monthNames[currentDate.getMonth() + 3]}:{' '}
									<span className='font-bold'>
										${(amount * 1.02 * 0.2).toFixed(2)}
									</span>
								</div>
								<div className='md:w-1/5 px-3'>
									{monthNames[currentDate.getMonth() + 4]}:{' '}
									<span className='font-bold'>
										${(amount * 1.02 * 0.2).toFixed(2)}
									</span>
								</div>
							</div>
							<div className='ml-3 mb-6 text-xs  px-3'>
								<p>
									A 5 month payment plan with interest
									<br />{' '}
									<span className='text-gray-600 text-xs italic'>
										(Credit score effected and 2% interest per month – 24%
										interest per annum)
									</span>
								</p>
							</div>
							<div className='-mx-3 mb-1 border-b-2'></div>
							<div className='-mx-3 mb-6'>
								<h2>
									A payment in full is still a good option, please visit our
									resources page to see how you could do this.
								</h2>
								<p className='text-red-600 text-xs italic'>
									{errors.paymentOption && 'Please select your payment option'}
								</p>
							</div>
							<div className='-mx-3 md:flex mb-6'>
								{showResponse ? (
									<div>Congrats you have been approved.</div>
								) : (
									<div className='-mx-3 md:flex mb-6'>
										<div className='md:w-1/2 px-3 mb-6 md:mb-0'>
											<button
												type='submit'
												className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full'
											>
												SUBMIT
											</button>
										</div>
									</div>
								)}
							</div>
						</>
					)}
				</div>
			</form>
		</>
	);
};

export default Payment;
