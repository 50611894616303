import React from 'react';
import { Route, Switch } from 'react-router-dom';

import './tailwind.output.css';

import Hardship from './Pages/Hardship/Hardship';
import Payment from './Pages/Payment/Payment';

function App() {
	return (
		<div className='App'>
			<div className='bg-white lg:px-8 pt-6 pb-8 mb-4 flex justify-between flex-wrap lg:pr-24 w-full'>
				<div className='mb-6'>
					<img src='https://lirp-cdn.multiscreensite.com/3d14db59/dms3rep/multi/opt/collection-agency-toronto-54fdc1a4-720w.jpg'></img>
				</div>
				<div className='flex flex-wrap items-center block uppercase text-blue-700 sm:text-xs'>
					<a className='ml-6' href='https://www.gobeyondcollect.com/'>
						Home
					</a>
					<a
						className='ml-6'
						href='https://www.gobeyondcollect.com/agency-collection-toronto'
					>
						About Us
					</a>
					<a
						className='ml-6'
						href='https://www.gobeyondcollect.com/bad-debt-collection-toronto'
					>
						Services
					</a>
					<a
						className='ml-6'
						href='https://www.gobeyondcollect.com/best-debt-collection-hamilton'
					>
						Clients
					</a>
					<a
						className='ml-6'
						href='https://www.gobeyondcollect.com/collection-for-landlords-hamilton'
					>
						News
					</a>
					<a
						className='ml-6'
						href='https://www.gobeyondcollect.com/best-collection-agancy-toronto-ontario'
					>
						Hardship
					</a>
					<a
						className='ml-6'
						href='https://www.gobeyondcollect.com/debt-collection-services-hamilton'
					>
						Contact
					</a>
					<a
						className='ml-6'
						href='https://www.gobeyondcollect.com/collection-agency-in-toronto'
					>
						Straetus
					</a>
				</div>
			</div>
			<div className='pt-6 flex justify-end lg:pr-48 text-blue-700'>
				<a
					className='ml-6'
					href='https://www.gobeyondcollect.com/privacy'
					target='_blank'
				>
					General privacy policy
				</a>
				<a
					className='ml-6'
					href='https://www.gobeyondcollect.com/hardship-form-privacy-statement'
					target='_blank'
				>
					Hardship privacy policy
				</a>
				<a
					className='ml-6'
					href='https://www.gobeyondcollect.com/payment-plan-privacy-statement'
					target='_blank'
				>
					Payment plan privacy policy
				</a>
			</div>
			<Switch>
				<Route path='/payment' component={Payment} />
				<Route path='/' component={Hardship} />
			</Switch>
			<div className='bg-gray-600 pt-12 flex justify-center flex-wrap '>
				<div>
					<img src='https://lirp-cdn.multiscreensite.com/3d14db59/dms3rep/multi/opt/collection-agencies-toronto-480w.png'></img>
				</div>
				<div className='text-white'>
					Go Beyond Collection Agency Inc.
					<br />
					TEL: 1-877-300-6006
					<br />
					FAX: 905-546-1505
					<br />
					100 Main Street East Suite 108 Hamilton, Ontario L8N 3W4
				</div>
			</div>
			<div className='bg-gray-600 pt-6 flex justify-center  flex-wrap text-white'>
				<span>Offices: </span>
				<span className='mx-2'>Ontario</span>
				<span className='mx-2'>British Columbia</span>
				<span className='mx-2'>Alberta</span>
				<span className='mx-2'>Saskatchewan</span>
				<span className='mx-2'>Manitoba</span>
				<span className='mx-2'>Prince Edward Island </span>
				<span className='mx-2'>Newfoundland</span>
			</div>
			<div className='bg-gray-600 pt-6 flex justify-center '>
				<div className='text-white text-xs justify-self-center'>
					&copy;2020 All Rights Reserved | Go Beyond Collections Inc. | Powered
					by <a href='https://www.ballamedia.ca/'> Balla Media</a>
				</div>
			</div>
		</div>
	);
}

export default App;
