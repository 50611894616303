import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';

const Hardship = () => {
	const { register, handleSubmit, errors } = useForm();
	const [showResponse, setShowResponse] = useState(false);
	const onSubmit = (data) => {
		axios
			.post(`/cbe/mailer.php`, { data })
			.then((res) => {
				if (res.data === 'success') {
					setShowResponse(true);
				}
			});
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className='bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4  my-2 mx-6  flex flex-col overflow-hiddenborder-solid rounded-lg border-2'>
				<div className='-mx-3 md:flex mb-6'>
					<h1>THE HARDSHIP FORM</h1>
					<input
						type='hidden'
						name='subject'
						value='THE HARDSHIP FORM'
						ref={register({ required: true })}
					></input>
				</div>
				<div className='-mx-3 md:flex mb-6'>
					<div className='md:w-1/2 px-3 '>
						<label
							className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2'
							htmlFor='grid-first-name'
						>
							First Name
						</label>
						<input
							className='appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4'
							id='grid-first-name'
							name='firstName'
							type='text'
							placeholder='Jane'
							ref={register({
								required: true,
								maxLength: 30,
								pattern: /^[A-Za-z]+$/i,
							})}
						/>
						<p className='text-red-600 text-xs italic'>
							{errors.firstName && 'First name is required'}
						</p>
					</div>
					<div className='md:w-1/2 px-3'>
						<label
							className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2'
							htmlFor='grid-last-name'
						>
							Last Name
						</label>
						<input
							className='appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4'
							id='grid-last-name'
							name='lastName'
							type='text'
							placeholder='Doe'
							ref={register({ required: true })}
						/>
						<p className='text-red-600 text-xs italic'>
							{errors.lastName && 'Last name is required'}
						</p>
					</div>
				</div>
				<div className='-mx-3 md:flex mb-6'>
					<div className='md:w-1/3 px-3 mb-6 md:mb-0'>
						<label
							className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2'
							htmlFor='grid-account-number'
						>
							Account number:
						</label>
						<input
							className='appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4'
							id='grid-account-number'
							name='accountNumber'
							ref={register({ required: true })}
							type='text'
						/>
						<p className='text-red-600 text-xs italic'>
							{errors.accountNumber && 'Account number is required'}
						</p>
					</div>
					<div className='md:w-1/3 px-3'>
						<label
							className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2'
							htmlFor='grid-creditor'
						>
							Creditor:
						</label>
						<input
							className='appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4'
							id='grid-creditor'
							name='creditor'
							ref={register({ required: true })}
							type='text'
						/>
						<p className='text-red-600 text-xs italic'>
							{errors.creditor && 'Creditor information  is required'}
						</p>
					</div>
					<div className='md:w-1/3 px-3'>
						<label
							className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2'
							htmlFor='grid-amount-of-debt'
						>
							Amount of Debt:
						</label>
						<input
							className='appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4'
							id='grid-amount-of-debt'
							name='ammountOfDebt'
							placeholder='99.99'
							min='1'
							ref={register({ required: true, min: 1, max: 9999999999 })}
							type='number'
						/>
						<p className='text-red-600 text-xs italic'>
							{errors.ammountOfDebt &&
								'Ammount of debt information is required'}
						</p>
					</div>
				</div>
				<div className='-mx-3 md:flex mb-6'>
					<div className='md:w-1/3 px-3 mb-6 md:mb-0'>
						<label
							className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2'
							htmlFor='grid-current-income'
						>
							Current Monthly Income:
						</label>
						<input
							className='appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4'
							id='grid-current-income'
							type='text'
							ref={register()}
							name='currentIncome'
						/>
					</div>
					<div className='md:w-1/3 px-3'>
						<label
							className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2'
							htmlFor='grid-current-expenses'
						>
							Current Monthly Expenses:
						</label>
						<input
							className='appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4'
							id='grid-current-expenses'
							type='text'
							ref={register()}
							name='currentExpenses'
						/>
					</div>
					<div className='md:w-1/3 px-3'>
						<label
							className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2'
							htmlFor='grid-current-debt-load'
						>
							Total of All Debts:
						</label>
						<input
							className='appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4'
							id='grid-current-debt-load'
							type='text'
							ref={register()}
							name='currentDebtLoad'
						/>
					</div>
				</div>
				<div className='-mx-3 md:flex mb-6'>
					<div className='md:w-full px-3'>
						<label
							className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2'
							htmlFor='grid-hardship'
						>
							Please explain your hardship in a few sentences:
						</label>
						<textarea
							className='appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 mb-3'
							id='grid-hardship'
							type='text'
							placeholder='Please explain your hardship in a few sentences'
							ref={register()}
							name='hardship'
						></textarea>
					</div>
				</div>
				<div className='-mx-3 md:flex mb-6'>
					<div className='md:w-1/2 px-3 mb-6 md:mb-0'>
						<label
							className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2'
							htmlFor='grid-phone'
						>
							Phone:
						</label>
						<input
							className='appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4'
							id='grid-phone'
							type='text'
							ref={register({
								required: true,
							})}
							name='phone'
							placeholder='(xxx) xxx-xxxx'
						/>
						<p className='text-red-600 text-xs italic'>
							{errors.email && 'Phone is required'}
						</p>
					</div>
					<div className='md:w-1/2 px-3'>
						<label
							className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2'
							htmlFor='grid-email'
						>
							Email:
						</label>
						<input
							className='appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4'
							id='grid-email'
							type='text'
							ref={register({
								required: true,
								maxLength: 50,
								pattern: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/i,
							})}
							name='email'
						/>
						<p className='text-red-600 text-xs italic'>
							{errors.email && 'Email is required'}
						</p>
					</div>
				</div>
				{showResponse ? (
					<div>
						Thank you for your enquiry. We will reply at the earliest possible
						opportunity.
					</div>
				) : (
					<div className='-mx-3 md:flex mb-6'>
						<div className='md:w-1/2 px-3 mb-6 md:mb-0'>
							<button
								type='submit'
								className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full'
							>
								SUBMIT
							</button>
						</div>
					</div>
				)}
			</div>
		</form>
	);
};

export default Hardship;
